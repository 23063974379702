import { createRouter, createWebHistory } from 'vue-router';
import IframePage from '@/components/IframePage.vue';
import AdminPage from '@/components/AdminPage.vue';
import LoginPage from '@/components/LoginPage.vue';

const routes = [
  {
    path: '/:shortcode',
    name: 'IframePage',
    component: IframePage,
    props: route => ({ shortcode: route.params.shortcode }),
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = localStorage.getItem('auth') === 'doubledouble';
    if (!isAuthenticated) {
      next({ name: 'LoginPage' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
