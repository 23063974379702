<template>
    <div class="admin-container">
      <h1>Alias Management</h1>
      <form @submit.prevent="addAlias">
        <input v-model="newAlias.shortcode" placeholder="Presentation Shortcode" required />
        <input v-model="newAlias.slug" placeholder="Alias Name (slug)" required />
        <button type="submit">Add Alias</button>
      </form>
      <ul>
        <li v-for="alias in aliases" :key="alias.id">
          {{ alias.slug }} - {{ alias.shortcode }}
          <button @click="deleteAlias(alias.id)">Delete</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { db, collection, getDocs, addDoc, deleteDoc, doc } from '@/firebase';
  
  export default {
    data() {
      return {
        newAlias: {
          shortcode: '',
          slug: '',
        },
        aliases: [],
      };
    },
    methods: {
      async addAlias() {
        if (this.newAlias.shortcode && this.newAlias.slug) {
          await addDoc(collection(db, 'aliases'), { ...this.newAlias });
          this.newAlias.shortcode = '';
          this.newAlias.slug = '';
          this.loadAliases();
        }
      },
      async deleteAlias(id) {
        await deleteDoc(doc(db, 'aliases', id));
        this.loadAliases();
      },
      async loadAliases() {
        const querySnapshot = await getDocs(collection(db, 'aliases'));
        this.aliases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      },
    },
    mounted() {
      this.loadAliases();
    },
  };
  </script>
  
  <style scoped>
  .admin-container {
    padding: 20px;
    background-color: #0d0e13;
    color: white;
    min-height: 100vh;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  </style>
  