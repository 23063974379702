import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB6BUpeqhXJ0u-HRIad3HTlCk6GTQFYd4w",
  authDomain: "case-dc-com.firebaseapp.com",
  projectId: "case-dc-com",
  storageBucket: "case-dc-com.appspot.com",
  messagingSenderId: "151933590643",
  appId: "1:151933590643:web:e5a14013c25e332b1cde06",
  measurementId: "G-87979V0747"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db, collection, getDocs, addDoc, deleteDoc, doc };
