<template>
    <div class="login-container">
      <h1>Admin Login</h1>
      <form @submit.prevent="login">
        <input v-model="password" type="password" placeholder="Enter Password" />
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        password: '',
        error: '',
      };
    },
    methods: {
      login() {
        if (this.password === 'doubledouble') {
          localStorage.setItem('auth', 'doubledouble');
          this.$router.push({ name: 'AdminPage' });
        } else {
          this.error = 'Incorrect password';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0d0e13;
    color: white;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
  }
  button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  p {
    color: red;
  }
  </style>
  