<template>
  <div class="iframe-container">
    <iframe
      v-if="resolvedShortcode"
      :src="`https://pitch.com/embed-link/${resolvedShortcode}`"
      allow="fullscreen"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { db, collection, getDocs } from '@/firebase';

export default {
  props: ['shortcode'],
  data() {
    return {
      aliases: [],
      resolvedShortcode: ''
    };
  },
  async mounted() {
    await this.loadAliases();
    const alias = this.aliases.find(alias => alias.slug === this.shortcode);
    this.resolvedShortcode = alias ? alias.shortcode : this.shortcode;
  },
  methods: {
    async loadAliases() {
      const querySnapshot = await getDocs(collection(db, 'aliases'));
      this.aliases = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },
  },
};
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
}

.iframe-container {
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: black;
  display: block;
  border: none;
}
</style>
